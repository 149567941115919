/* App.css */

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
 
  height: 100vh;
  background-color: 'yellow';
}
input::placeholder {
  color: '#9191ad';
 }
.task-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: blue; /* Default background color */
}

.task-card.completed {
  background-color: green; /* Background color when task is completed */
}

.task-card span {
  flex-grow: 1;
  margin-right: 10px;
}

.task-actions {
  display: flex;
  align-items: center;
}

.task-icon {
  cursor: pointer;
  margin-left: 5px;
}
